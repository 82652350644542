<template>
  <div id="app">
    <component :is="defaultLayout">
        <router-view />
    </component>
  </div>
</template>

<script>
    const defaultLayout = () => import('@/layouts/default.vue')

import VueExtendLayouts from 'vue-extend-layout'
export default {
  name: 'App',
  components: { defaultLayout },
  computed: {
    defaultLayout() {
      return 'defaultLayout'
    }
  }
}
</script>
