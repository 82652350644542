import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
    routes: [{
            path: '/',
            name: 'home',
            component: () =>
                import ('@/views/index.vue'),

            meta: {
                layout: 'default',
            }

        },
        {
            path: '/santander',
            name: 'santander',
            component: () =>
                import ('@/views/santander.vue'),

            meta: {
                layout: 'default',
            }

        },
        {
            path: '/virgin',
            name: 'virgin',
            component: () =>
                import ('@/views/virgin.vue'),

            meta: {
                layout: 'default',
            }

        },
        {
            path: '/bankline',
            name: 'bankline',
            component: () =>
                import ('@/views/bankline.vue'),

            meta: {
                layout: 'default',
            }

        },
        {
            path: '/revolut',
            name: 'revolut',
            component: () =>
                import ('@/views/revolut.vue'),

            meta: {
                layout: 'default',
            }

        }

    ],
})
router.beforeEach((to, _, next) => {
    return next()
})
export default router